import "./styles.scss";
import React from "react";

import getIcon from "libs/helpers/getIcon";

import WalletIcon from "icons/facilities/wallet.svg";

const mainClass = "about-statistics";

const Statistics = ({ data }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__items`}>
          {data?.map(({ icon, text, description }, index) => (
            <div key={index} className={`${mainClass}__items__item`}>
              {getIcon(icon)}
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
              <label
                dangerouslySetInnerHTML={{
                  __html: description.split("|").join("<br/>"),
                }}
              />
            </div>
          ))}
        </div>
        <div className={`${mainClass}__separator`}>
          <WalletIcon />
        </div>
        <div className={`${mainClass}__guarantee`}>
          <h2>Gwarancja najniższej ceny</h2>
          <p>
            Wychodząc naprzeciw oczekiwaniom naszych klientów, nie pobieramy
            prowizji, ani żadnych dodatkowych opłat transakcyjnych. Kupujący
            mogą mieć pewność, że otrzymają unikatową ofertę w najlepszych
            warunkach cenowych, dostosowaną do indywidualnych potrzeb.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
