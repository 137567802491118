import "./styles.scss";
import React from "react";

import Button from "components/Button";

const mainClass = "about-contact-us";

const ContactUs = () => {
  const scrollToForm = () => {
    document.querySelector("#contact-form").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__text`}>
          <h3>Jeśli interesuje Cię</h3>
          <span>
            inwestowanie w apartamenty hotelowe,
            <br />
            <span>sprawdź, co możemy Ci zaoferować</span>
          </span>
        </div>
        <Button onClick={scrollToForm}>Skontaktuj się z nami</Button>
      </div>
    </div>
  );
};

export default ContactUs;
