import "./styles.scss";
import React from "react";

const mainClass = "about-experts";

const Experts = ({ title, description, data }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          <p>{description}</p>
        </div>
        <h3>
          Nasi eksperci ds. inwestycji
          <br />
          <span>w nieruchomości</span>
        </h3>
        <div className={`${mainClass}__items`}>
          {data?.map(({ zdjecie, name, desc }, index) => (
            <div key={index} className={`${mainClass}__items__item`}>
              <div className={`${mainClass}__items__item__image`}>
                <img src={zdjecie?.sourceUrl} alt={zdjecie?.altText} />
              </div>
              <div key={index} className={`${mainClass}__items__item__text`}>
                <label>{name}</label>
                <p
                  dangerouslySetInnerHTML={{
                    __html: desc,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experts;
