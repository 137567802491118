import "styles/pages/home-page.scss";

import React from "react";
import { graphql } from "gatsby";

import {
  ContactUs,
  Experts,
  Media,
  SelectedOffer,
  Statistics,
  Partnership,
} from "page_components/about";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";

import AboutHero from "images/about-hero.png";

const About = ({ data }) => {
  const about_us = data.aboutUs.aboutUs;

  const seo = data.aboutUs.seo;
  const schema = JSON.parse(data.aboutUs.seo.schema.raw);

  return (
    <Layout
      seo={{ title: seo?.title, description: seo?.metaDesc, schema }}
      location="O nas"
    >
      <PageHeader
        image={AboutHero}
        title="O nas"
        thin_title={about_us?.heroText}
        text={about_us?.heroDescription}
      />
      <Statistics data={about_us?.statistics} />
      <SelectedOffer
        text={about_us?.selectedOfferText}
        items={about_us?.selectedOfferFacilities}
      />
      <Experts
        title={about_us?.expertsTitle}
        description={about_us?.expertsText}
        data={about_us?.experts}
      />
      <ContactUs />
      <Partnership data={about_us?.clients} />
      {/* <Media data={about_us?.slider} /> */}
    </Layout>
  );
};

export default About;

export const query = graphql`
  query aboutUs {
    aboutUs: wpPage(id: { eq: "cG9zdDoxMTE2" }) {
      aboutUs {
        heroText
        heroDescription
        statistics {
          icon
          text
          description
        }
        statisticsText
        statisticsDescription
        selectedOfferText
        selectedOfferFacilities {
          icon
          text
        }
        expertsTitle
        expertsText
        experts {
          name
          job
          desc
          zdjecie {
            altText
            sourceUrl
          }
        }
        clients {
          logo {
            altText
            sourceUrl
          }
        }
      }
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
    investmentsForMap: allWpInvestment(
      filter: { investments: { hideInvestment: { eq: true } } }
    ) {
      nodes {
        title
        id
        uri
        slug
        investments {
          category
          backPercentage
          subdivisionModel
          ready
          contactLength
          deadline
          investLogo {
            sourceUrl
            altText
          }
          mainImg {
            sourceUrl
          }
          mainTitle
          priceMin
          priceMax
          vacationsDaysCustom
          subtitle
          shortLocation
          surfacemin
          locationLng
          locationLat
          parking
          spa
          restaurant
          skiRoom
          reception
          gym
          conferenceRoom
          bikeRoom
          bar
          poolIn
          poolOut
          terrace
        }
      }
    }
  }
`;
