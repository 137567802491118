import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import Logo1 from "images/logos/logo-osada.png";
import Logo2 from "images/logos/logo-piemonty.png";
import Logo3 from "images/logos/logo-sea-shell.png";
import Logo4 from "images/logos/logo-tre.png";

import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "about-partnership";

const partnership_data = [Logo1, Logo2, Logo3, Logo4, Logo3, Logo4];

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const sliderSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  draggable: true,
  autoplay: true,
  autoplaySpeed: 3000,
  useTransform: false,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 830,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
  nextArrow: (
    <CustomArrow className="about-media__arrow about-media__arrow--next" />
  ),
  prevArrow: (
    <CustomArrow className="about-media__arrow about-media__arrow--prev" />
  ),
};

const Partnership = ({ data }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <h2>Współpracujemy z najlepszymi</h2>
        <Slider {...sliderSettings}>
          {data?.map(({ logo }, index) => (
            <div key={index}>
              <div key={index} className={`${mainClass}__slide`}>
                <img src={logo?.sourceUrl} alt={logo?.altText} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Partnership;
